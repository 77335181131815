<template>
  <!-- ModelViewer Slide -->
  <div
      class="uk-width-1-1 uk-cover-container"
      uk-height-viewport
      v-show="this.$parent.content_type === 'modelviewer'"
  >
    <div id="modelviewer">
      <iframe
          v-bind:src="this.viewerURL"
          style="border: 0px #ffffff none; pointer-events: all;"
          uk-height-viewport
          name="modelviewer"
          scrolling="no"
          frameborder="1"
          marginheight="0px"
          marginwidth="0px"
          height="100%"
          width="100%"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
if (typeof nw === 'undefined') {
  let nw = window.nw;
}

export default {
  name: 'modelviewer-slide',
  data: function () {
    return {
      debug: false,
      config: [], // Local config copy for easier path adjustments
      viewerURL: '',
    };
  },
  mounted() {
    if (this.debug) console.log('Mount ModelViewer Slide');

    // Broadcast that our component is ready to be used
    this.$emit('mountedSlide', 'modelviewer-slide');
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessageReceived);
  },
  methods: {
    //
    // Standard Component Methods
    // Called to enable (activate our module)
    load: function (id) {
      if (this.debug) console.log('Init ModelViewer Slide', this.$parent.config[id].name);

      // Make a copy of our parents relevant config
      this.config = JSON.parse(JSON.stringify(this.$parent.config[id].content));
      this.$parent.content_type = this.$parent.config[id].content.content_type;
      this.$parent.setupScene(id);

      // Listen to events from our iFrame
      window.addEventListener('message', this.onMessageReceived);

      // Load our viewer
      this.viewerURL = window.buildPath + '/resources/js/webkit/libs/model-viewer/index.html';
    },
    setup: function (id) {
      if (this.debug) console.log('Setup ModelViewer Slide');
    },
    reset: function (id) {
      if (this.debug) console.log('Reset ModelViewer Slide');

      if (this.$parent.config[id].content.content_type === 'modelviewer') {
        this.showOverlays();
      }
    },
    //
    // Custom Component Methods
    //
    onMessageReceived: function (event) {
      if (event.data === 'viewer:loaded') {
        if (this.debug)
          console.log('Got Viewer Loaded Event', event);
        this.loadModel();
      }
    },
    loadModel: function () {
      if (this.debug) console.log('Load Model:' + window.applicationPath + this.config.source);

      const event = new CustomEvent("loadModel", {detail: window.applicationPath + this.config.source});
      document.getElementById("app").dispatchEvent(event);
    },
    showOverlays: function () {
      if (this.debug) console.log('Show all Overlays');

      const points = document.getElementsByClassName('modelviewer-poi');

      for (let i = 0; i < points.length; i++) points[i].style.display = 'flex !important';
    },
    showOverlay: function (id) {
      if (this.debug) console.log('Show Overlay:', id);
      document.getElementById(id).classList.remove('uk-hidden');
    },
    hideOverlay: function () {
      if (this.debug) console.log('Hide Overlay');
      const points = document.getElementsByClassName('modelviewer-poi');

      for (let i = 0; i < points.length; i++) points[i].classList.add('uk-hidden');
    },
  },
};
</script>

<style scoped></style>
