<template>
  <!-- Menu Content Block -->
  <div>
    <div v-bind:class="[ properties.classes ]">

      <dl class="uk-description-list uk-description-list-divider">
        <dt v-html="content.title"></dt>
        <dd v-html="content.html_description"></dd>
      </dl>

      <div v-for="button in content.buttons">
        <button
            class="uk-button uk-button-default uk-width-1-1 uk-margin-small-bottom uk-flex uk-flex-center uk-flex-middle"
            @click="$parent.$parent.$parent.executeFunctions( button, button.events ); $parent.$parent.$parent.trackEvent('Menu', 'Interact', button.display_name)"
            ref="{button.name}"
            v-bind:id="[button.name]"
            v-bind:class="[button.classes]">
          <span class="uk-margin-small-right" v-html="button.html_content"></span>
          <span v-if="button.icon" v-bind:class="[button.icon]"></span>
          <img v-if="button.file" v-bind:alt="button.display_name" v-bind:src="[assetPath + button.file]">
        </button>
      </div>

    </div>
  </div>

</template>

<script>
if (typeof nw === "undefined") {
  let nw = window.nw;
}
export default {
  name: "menu-contentBlock",
  props: ['properties'],
  data: function () {
    return {
      debug: true,
      assetPath: null,
      content: {
        title: null,
        description: null,
        classes: [],
        buttons: [],
      }
    }
  },
  mounted: function () {
    if (this.debug) console.log('Init Menu ContentBlock');
    const self = this;
    this.assetPath = nw.App.startPath + nw.global.workingDir;

    this.load();
  },
  methods: {
    load: function () {
      if (this.debug) console.log("Load Menu ContentBlock");

      this.content.title = this.properties.content.title;
      this.content.description = this.properties.content.html_description;
      this.content.classes = this.properties.content.classes;
      this.content.buttons = this.properties.content.items;
    }
  }
}
</script>

<style scoped>

</style>
