<template>
    <div uk-grid class="uk-grid-collapse">

        <p-check class="p-switch uk-margin-small-top" name="autostart" color="success" v-if="this.$parent.manifest.config.autostart" v-model="preferences.autostart">Autostart</p-check>

        <hr v-if="this.$parent.manifest.config.autoUpdate" class="uk-margin-small uk-width-1-1">
        <p-check class="p-switch" name="autostart" color="success" v-if="this.$parent.manifest.config.autoUpdate" v-model="preferences.autoUpdate">Check for Updates</p-check>


        <hr class="uk-margin-small uk-width-1-1">
        <p-check class="p-switch uk-margin-top" name="rememberWindowPosition" color="success" v-model="preferences.rememberWindowPosition">Remember Window Position</p-check>
        <button class="uk-button uk-button-text uk-width-1-1 uk-margin-small uk-text-center" @click="$parent.resizeWindow()">Reset Window Position</button>

        <p-check v-if="this.$parent.manifest.config.kiosk" class="p-switch" name="kiosk" color="success" v-model="preferences.kiosk">Kiosk Mode</p-check>

        <div class="uk-width-1-1 uk-margin-small-top">
            <button class="uk-align-right uk-button uk-button-info" @click="savePreferences()">Save</button>
        </div>

    </div>
</template>

<script>
    export default {
        name: "options-menu",
        data: function () {
            return {
                debug: true,
                preferences: nw.global.preferences
            }
        },
        computed: {
            kioskState() {
                return this.preferences.kiosk;
            }
        },
        watch: {
            kioskState() {
                if(this.debug) console.log('Configure Window:');
                nw.global.configureWindow();
            }
        },
        methods: {
            savePreferences: function() {
                if(this.debug) console.log('Save Preferences');
                nw.global.savePreferences();
            }
        }
    }
</script>

<style scoped>

</style>