<template>
  <!-- Drawer -->
  <vk-offcanvas-content id="drawermenu">
    <vk-offcanvas flipped overlay :show.sync="show">

      <vk-offcanvas-close @click="show = false"></vk-offcanvas-close>

      <h1 v-html="title"></h1>
      <ul class="uk-nav uk-nav-default" v-bind:class="this.classes">
        <li v-for="item in items"
            :ref="item.name"
            @click="executeFunction( item.events )"
            v-bind:class="[ item.classes ]">
          <a>
            <span v-html="item.content"></span>
          </a>
        </li>
      </ul>

    </vk-offcanvas>
  </vk-offcanvas-content>

</template>

<script>
export default {
  name: "drawermenu-element",
  data: function () {
    return {
      debug: false,
      show: false,
      id: 0,
      title: null,
      items: [],
      classes: [],
    }
  },
  methods: {
    //
    // Standard Component Methods
    executeFunction(events) {
      for (let e = 0; e < events.length; e++) {
        this.$parent.executeFunctionByName(events[e].function, events[e].parameter);
      }
    },
    // Inits our Component
    load: function (id) {
      if (this.debug) console.log('Init DrawerMenu Element', this.$parent.config[this.id].name);

      const self = this;

      this.title = this.$parent.config[id].elements[this.id].display_name;
      this.classes = this.$parent.config[id].elements[this.id].classes;
      this.items = this.$parent.config[id].elements[this.id].content;
    },
    setup: function (id) {
      if (this.debug) console.log('Setup DrawerMenu Element');

    },
    reset: function (id) {
      if (this.debug) console.log('Reset DrawerMenu Element');

      this.items = [];
      this.show = false;
    },
    open: function (name) {
      if (this.debug) console.log('Open DrawerMenu');

      this.show = true;

      // Lookup our drawermenu id by name
      this.id = this.$parent.config[this.$parent.currentSlide].elements.findIndex(function (item, i) {
        return item.name === name
      });

      this.$parent.trackContentInteraction('Opened', 'DrawerMenu', this.title);
      this.$parent.trackEvent('DrawerMenu', 'Open', this.title);
    },
    close: function () {
      if (this.debug) console.log('Close DrawerMenu');

      this.show = false;

      this.$parent.trackContentInteraction('Closed', 'DrawerMenu', this.title);
      this.$parent.trackEvent('DrawerMenu', 'Close', this.title);
    }
  }
}
</script>

<style scoped>

</style>
