<template>
  <!-- Tabmenu -->
  <div v-bind:class="[ 'uk-position-bottom', classes ]" v-show="visible">
    <ul v-bind:class="[ 'uk-tab', 'uk-tab-bottom', 'uk-child-width-expand', 'uk-text-uppercase', 'uk-text-bold', 'uk-margin-remove-bottom', 'uk-padding-small', 'uk-padding-remove', content.classes ]">
      <li v-for="item in content.items"
          :ref="item.name"
          @click="executeFunction( item.events ); $parent.$parent.$parent.trackEvent('TabMenu', 'Click', item.name)"
          v-bind:class="[ item.classes ]">
        <a>
          <span v-html="item.html_content"></span>
        </a>
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  name: "tabmenu-element",
  props: ['index'],
  data: function () {
    return {
      debug: false,
      visible: false,
      classes: [],
      content: {
        classes: [],
        items: [],
      },
    }
  },
  computed: {},
  methods: {
    //
    // Standard Component Methods
    executeFunction(events) {
      for (let e = 0; e < events.length; e++) {
        this.$parent.executeFunctionByName(events[e].function, events[e].parameter);
      }
    },
    // Inits our Component
    load: function (id) {
      if (this.debug) console.log('Init Tabmenu Element', this.$parent.config[this.$parent.currentSlide].elements[id].name);
      // Id ref to our slide, while our index is always zero, meaning our tabmenus should be our first element
      this.classes = Object.assign(this.$parent.config[this.$parent.currentSlide].elements[id].classes);
      this.content.classes = Object.assign(this.$parent.config[this.$parent.currentSlide].elements[id].content.classes);
      this.content.items = Object.assign(this.$parent.config[this.$parent.currentSlide].elements[id].content.items);

      this.visible = true;
    },
    setup: function (id) {
      if (this.debug) console.log('Setup Tabmenu Element', id);

      this.visible = false;
      this.classes = [];
      this.content = [];

      this.load(id);

      if (this.$parent.config[this.$parent.currentSlide].elements[id]) {
        // TODO Add support for a dynamic loading state
        //if (this.$parent.config[this.$parent.currentSlide].elements[id].content.opened)
        this.load(id);
      }
    },
    reset: function (id) {
      if (this.debug) console.log('Reset Tabmenu Element');

      this.visible = false;
      this.classes = [];
      this.content = [];
    }
  }
}
</script>

<style scoped>
ul {
  background-color: rgba(248, 248, 248, 0.7);
  pointer-events: all;
}

li {
  border-right: 1px solid #e5e5e5;
}

li:nth-last-child(1) {
  border-right: none;
}

li a {
  display: table;
  width: 100%;
  height: 100%;
  padding: 0.5em;
}

li a span {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

/*.uk-tab::before {*/
/*    border-bottom-width: 2px;*/
/*}*/
/*.uk-tab-bottom > * > a {*/
/*    border-top: 2px solid transparent;*/
/*}*/

/*.uk-tab > .uk-active {*/
/*    background: #212952;*/
/*}*/
/*.uk-tab > .uk-active > a {*/
/*    border-color: #d76921;*/
/*    color: white;*/
/*}*/

a {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
</style>
