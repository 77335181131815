<template>
  <!-- Drawer -->
  <vk-offcanvas-content id="drawer">
    <vk-offcanvas
        :flipped="position==='right'"
        :overlay="overlay"
        :stuck="lock"
        :show.sync="show"
    >

      <vk-offcanvas-close @click="show = false"></vk-offcanvas-close>

      <h1 v-html="drawer.title"></h1>

      <div
          v-html="drawer.content"
          v-bind:class="[ drawer.classes ]">
      </div>

      <!-- Dynamic Elements -->
      <component
          v-for="(contentBlock, index) in usedContentBlocks"
          v-bind:index="index"
          v-bind:key="contentBlock.name"
          v-bind:is="contentBlock.content.content_type + '-contentBlock'"
          v-bind:properties="contentBlock"
          :ref="$parent.genRefName( contentBlock.content.content_type + 'ContentBlock' )"
      >
      </component>

    </vk-offcanvas>
  </vk-offcanvas-content>

</template>

<script>
if (typeof nw === "undefined") {
  let nw = window.nw;
}
export default {
  name: "drawer-element",
  data: function () {
    return {
      debug: false,
      show: false,
      lock: false,
      overlay: true,
      position: 'right',
      animation: 'slide',
      drawer: {
        title: null,
        content: null,
        classes: []
      },
      usedContentBlocks: []
    }
  },
  watch: {
    show: function (val) {
      // We use an extra watch to call our close function, to catch drawers, which are closed via a click on the overlay
      // since we cannot call our click function directly there
      if (val === false) this.close();
    }
  },
  methods: {
    //
    // Standard Component Methods

    // Inits our Component
    load: function (id) {
      if (this.debug) console.log('Init Drawer Element', this.$parent.config[id].name);

      const self = this;

      String.prototype.replaceAll = function (search, replacement) {
        var target = this;
        return target.replace(new RegExp(search, 'g'), replacement);
      };

      this.setup(id);
    },
    setup: function (id) {
      if (this.debug) console.log('Setup Drawer Element');

      if (this.$parent.config[this.$parent.currentSlide].elements[id]) {
        if (this.$parent.config[this.$parent.currentSlide].elements[id].content.opened)
          this.open(this.$parent.config[this.$parent.currentSlide].elements[id].name);
        else this.show = false;
      } else this.show = false;

      // Bind events
      let events = this.$parent.config[this.$parent.currentSlide].elements[id].events;
      if (this.debug) console.log("Drawer Events:", events);

      for (let i = 0; i < events.length; i += 1) {
        if (this.debug) console.log("Event:", events[i]);

        switch (events[i].trigger) {
          case "onDrawerElementOpened":
            if (this.debug) console.log("Bind Opened Event")
            this.$on("onDrawerElementOpened", this[events[i].function](events[i].parameter));
            break;
          case "onDrawerElementClosed":
            if (this.debug) console.log("Bind Closed Event")
            this.$on("onDrawerElementClosed", this[events[i].function](events[i].parameter));
            break;
        }
      }

    },
    reset: function (id) {
      if (this.debug) console.log('Reset Drawer Element');

      this.setup(id);
    },
    open: function (name) {
      if (this.debug) console.log('Open Drawer', name);

      this.show = true;
      this.$emit('onDrawerElementOpened', name);

      this.$parent.trackContentInteraction('Opened', 'Drawer', name);
      this.$parent.trackEvent('Drawer', 'Open', name);

      // Lookup our drawer's id by name
      const id = this.$parent.config[this.$parent.currentSlide].elements.findIndex(function (item, i) {
        return item.name === name
      });

      // Then gather a list of the used content blocks within this element
      if (this.$parent.config[this.$parent.currentSlide].elements[id]) {
        this.usedContentBlocks = [...new Set(this.$parent.config[this.$parent.currentSlide].elements[id].content_blocks.map(x => x))];

        this.drawer.title = this.$parent.config[this.$parent.currentSlide].elements[id].content.title;
        this.drawer.content = this.$parent.config[this.$parent.currentSlide].elements[id].content.html_body;
        this.drawer.content = this.drawer.content.replaceAll("src='/", "src='" + nw.App.startPath + nw.global.workingDir);

        this.drawer.classes = this.$parent.config[this.$parent.currentSlide].elements[id].classes;

        this.overlay = this.$parent.config[this.$parent.currentSlide].elements[id].content.overlay;
        this.lock = this.$parent.config[this.$parent.currentSlide].elements[id].content.lock;
        this.position = this.$parent.config[this.$parent.currentSlide].elements[id].content.position;
        this.animation = this.$parent.config[this.$parent.currentSlide].elements[id].content.animation;
      } else {
        if (this.debug) console.error('Tried to load non existing drawer:', name);

        if (nw.global.developmentMode || this.debug) {
          this.drawer.title = "Not found";
          this.drawer.content = "Please check that you referenced a valid item name: <strong>" + name + "</strong>";
          this.drawer.classes = ['uk-text-danger'];
        }
        this.usedContentBlocks.splice(0);
      }
      if (this.debug) console.log('Used Content Blocks:', this.usedContentBlocks);
    },
    close: function (name) {
      if (this.debug) console.log('Close Drawer', name);

      if (this.show) {
        this.show = false;
        this.$emit('onDrawerElementClosed', name);
        // Reset our usedContentBlocks array upon close, so it correctly re-draws upon opening with different content
        this.usedContentBlocks.splice(0);

        this.$parent.trackContentInteraction('Closed', 'Drawer', name);
        this.$parent.trackEvent('Drawer', 'Close', name);
      }
    }
  }
}
</script>

<style scoped>

</style>
