<template>
  <!-- Video Slide -->
  <div class="uk-width-1-1 uk-cover-container" uk-height-viewport v-show="this.$parent.content_type === 'video'">
    <img id="poster" v-bind:class="isVideo" uk-cover>
    <video id="videoPlayer"
           v-bind:controls="controls"
           v-bind:class="isVideo"
           v-bind:autoplay="autoplay"
           v-bind:muted="autoplay"
           v-bind:loop="loop"
           v-bind:data-matomo-title="title"
           v-show="this.$parent.content_type === 'video'"
           preload="auto">
    </video>
  </div>
</template>

<script>
if (typeof nw === "undefined") {
  let nw = window.nw;
}
export default {
  name: "video-slide",
  data: function () {
    return {
      debug: false,
      title: null,
      poster: null,
      controls: false,
      autoplay: false,
      loop: false,
      videoPlayer: null
    }
  },
  computed: {
    isVideo: function () {
      return {
        'element-transition': this.$parent.content_type !== 'video'
      };
    }
  },
  mounted() {
    if (this.debug) console.log('Mount Video Slide');

    this.poster = document.getElementById('poster');
    this.videoPlayer = document.getElementById('videoPlayer');

    // Broadcast that our component is ready to be used
    this.$emit('mountedSlide', 'video-slide');
  },
  methods: {
    //
    // Standard Component Methods

    // Inits our Component
    load: function (id) {
      if (this.debug) console.log('Init Video Slide', this.$parent.config[id].name);

      const self = this;
      this.poster = document.getElementById('poster');
      this.videoPlayer = document.getElementById('videoPlayer');

      if (self.$parent.config[id].content.poster) {
        if (self.debug) console.log('Setup Poster');

        const imageResetHelper = function () {
          if (self.$parent.config[id].content.source) {
            if (self.debug) console.log('Hide Video');

            self.videoPlayer.style.display = 'none';

            const videoResetHelper = function () {
              if (self.debug) console.log('Video Reset');
              self.poster.removeEventListener('load', imageResetHelper);
              self.videoPlayer.removeEventListener('canplaythrough', videoResetHelper);
              self.$parent.content_type = self.$parent.config[id].content.content_type;
              self.videoPlayer.style.display = 'block';
            };
            self.videoPlayer.addEventListener('canplaythrough', videoResetHelper);

            self.$parent.setupScene(id);
            self.videoPlayer.src = nw.App.startPath + nw.global.workingDir + self.$parent.config[id].content.source;

            if (typeof _paq !== 'undefined') {
              _paq.push(['MediaAnalytics::scanForMedia']);
            }
          }
        };

        this.poster.addEventListener('load', imageResetHelper);
        this.poster.src = nw.App.startPath + nw.global.workingDir + self.$parent.config[id].content.poster;
      } else {
        if (this.debug) console.warn('Found no poster for the Video!');
      }
    },
    setup: function (id) {
      if (this.debug) console.log('Setup Video Slide');

      // get our starting slide parameters
      const content = this.$parent.config[id].content;
      const events = this.$parent.config[id].events;

      this.title = this.$parent.config[id].display_name;
      this.controls = content.controls;
      this.autoplay = content.autoplay;
      this.loop = content.loop;

      // and setup its properties
      if (this.debug) console.log('Configure Video. Controls: ', this.controls, ' Autoplay: ', this.autoplay, ' Loop: ', this.loop);

      if (events !== undefined && events.length > 0) {
        for (let i = 0; i < events.length; i += 1) {
          if (this.debug) console.log('Add Event For: ', events[i]);
          this.videoPlayer.addEventListener(events[i].function, this.$parent[events[i].parameter]);
        }
      }
    },
    reset: function (id) {
      if (this.debug) console.log('Reset Video Slide');

      // get our video player instance
      const events = this.$parent.config[id].events;
      if (this.debug) console.log('Reset Events', events);

      // and unbind all existing event listeners
      if (events !== undefined && events.length > 0) {
        for (let i = 0; i < events.length; i += 1) {
          if (this.debug) console.log('Remove Event For: ', events[i]);
          this.videoPlayer.removeEventListener(events[i][0], this.$parent[events[i][1]]);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
