<template>
  <!-- Media Slider -->
  <div>
    <div v-bind:class="[ properties.classes ]">
      <div class="uk-inline">
        <div v-bind:id="properties.name + '-slider'"
             class="splide">
          <div class="splide__track">
            <ul class="splide__list">
              <li v-for="item in properties.content.media_slider_items" :key="item.name" class="splide__slide"
                  data-track-content=""
                  data-content-name="Media Slider Item"
                  v-bind:data-content-piece="[item.display_name]">
                <img
                    v-if="isImageAvailable(item)"
                    v-bind:src="getImageUrl(item)"
                    v-bind:alt="item.content.alt"/>

                <button v-if="item.content.content_type === 'video'" type="button"
                        class="uk-position-center uk-position-small uk-icon interactive"
                        v-on:click="openLightBox(); $parent.$parent.$parent.trackEvent('MediaSlider', 'OpenLightBox', item.display_name)">
                  <svg version="1.1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </button>

                <h4 v-html="item.display_name"></h4>
                <p v-html="item.html_description"></p>
              </li>
            </ul>
          </div>
        </div>

        <button v-if="properties.content.fullscreen" type="button"
                class="uk-position-top-right uk-position-small uk-icon interactive"
                v-on:click="openLightBox(); $parent.$parent.$parent.trackEvent('MediaSlider', 'Fullscreen', properties.name + '-slider')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor"
               stroke="none">
            <path
                d="M416 176V86.63L246.6 256L416 425.4V336c0-8.844 7.156-16 16-16s16 7.156 16 16v128c0 8.844-7.156 16-16 16h-128c-8.844 0-16-7.156-16-16s7.156-16 16-16h89.38L224 278.6L54.63 448H144C152.8 448 160 455.2 160 464S152.8 480 144 480h-128C7.156 480 0 472.8 0 464v-128C0 327.2 7.156 320 16 320S32 327.2 32 336v89.38L201.4 256L32 86.63V176C32 184.8 24.84 192 16 192S0 184.8 0 176v-128C0 39.16 7.156 32 16 32h128C152.8 32 160 39.16 160 48S152.8 64 144 64H54.63L224 233.4L393.4 64H304C295.2 64 288 56.84 288 48S295.2 32 304 32h128C440.8 32 448 39.16 448 48v128C448 184.8 440.8 192 432 192S416 184.8 416 176z"/>
          </svg>
        </button>

        <div v-bind:id="properties.name + '-thumbnails'" class="splide uk-margin">
          <div v-if="properties.content.navigation === 'thumbnail'" class="splide__track">
            <ul class="splide__list">

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
if (typeof nw === "undefined") {
  let nw = window.nw;
}
export default {
  name: "mediaslider-contentBlock",
  props: ['properties'],
  data: function () {
    return {
      debug: false,
      assetPath: null,
      primarySlider: null,
      secondarySlider: null,
      lightbox: null,
    }
  },
  mounted: function () {
    if (this.debug) console.log('Init MediaSlider ContentBlock:', this.properties.name);
    const self = this;
    this.assetPath = nw.App.startPath + nw.global.workingDir;

    this.load();
  },
  methods: {
    load: function () {
      if (this.debug) console.log("Load MediaSlider ContentBlock");

      // Create the main slider.
      this.primarySlider = new Splide('#' + this.properties.name + '-slider', {
        type: 'slide',
        rewind: false,
        trimSpace: true,
        autoWidth: true,
        autoHeight: true,
        height: this.properties.content.direction === 'ttb' ? '10rem' : 0,
        focus: 'center',
        pagination: this.properties.content.navigation === 'bulletpoints',
        gap: this.properties.content.gap,
        interval: 5000,
        autoplay: this.properties.content.autoplay,
        arrows: this.properties.content.arrows,
        direction: this.properties.content.direction,
        perPage: this.properties.content.items_per_row,
        perMove: this.properties.content.items_per_interaction,
        padding: this.properties.content.side_padding
      });

      if (this.properties.content.navigation === 'thumbnail') {
        // Create and mount the thumbnails slider.
        this.secondarySlider = new Splide('#' + this.properties.name + '-thumbnails', {
          rewind: true,
          fixedWidth: 100,
          fixedHeight: 64,
          isNavigation: true,
          gap: 10,
          focus: 'center',
          pagination: false,
          cover: true,
        }).mount(); // .mount( { Video } );

        // Set the thumbnails slider as a sync target and then call mount.
        this.primarySlider.sync(this.secondarySlider).mount();


        for (let i = 0; i < this.properties.content.media_slider_items.length; i++) {
          this.secondarySlider.add('<li class="splide__slide"><img src="' + this.assetPath + this.properties.content.media_slider_items[i].content.source + '"/></li>');
        }
        this.secondarySlider.refresh();

      } else {
        // and finally mount it
        this.primarySlider.mount();
      }

      let elementList = [];
      for (let i = 0; i < this.properties.content.media_slider_items.length; i++) {
        elementList.push({
          'href': this.assetPath + this.properties.content.media_slider_items[i].content.source,
          'type': this.properties.content.media_slider_items[i].content.content_type,
          'title': this.properties.content.media_slider_items[i].display_name,
          'description': this.properties.content.media_slider_items[i].html_description,
          'descPosition': this.properties.content.description_position,
        });
      }

      this.lightbox = Glightbox({
        slideEffect: this.properties.content.transition_animation,
        touchNavigation: true,
        loop: true,
      });
      this.lightbox.setElements(elementList);

      this.lightbox.on('open', () => {
        if (this.debug) console.log('LightBox Opened');
        this.$parent.$parent.lock = true;
      });
      this.lightbox.on('close', () => {
        if (this.debug) console.log('LightBox Closed');
        this.$parent.$parent.lock = false;
      });
    },
    openLightBox: function () {
      if (this.debug) console.log('Open LightBox at index: ', this.primarySlider.index);

      this.lightbox.openAt(this.primarySlider.index);
    },
    isImageAvailable(item) {
      if (!this.assetPath || this.assetPath === 'undefined')
        return false;
      else if (item.content.content_type === 'image')
        return (item.content.thumbnail && item.content.thumbnail !== 'undefined');
      else
        return (item.content.poster && item.content.poster !== 'undefined');
    },
    getImageUrl(item) {
      if (this.isImageAvailable(item)) {
        return this.assetPath + (item.content.content_type === 'image' ? item.content.thumbnail : item.content.poster);
      } else {
        // Return a placeholder image or an empty string if thumbnail is undefined or null
        return ''; // or return a placeholder image path
      }
    }
  }
}
</script>

<style scoped>

</style>
