<template>
    <!-- Drawer -->
    <vk-offcanvas-content id="drawer">
        <vk-offcanvas flipped overlay :show.sync="show">

            <vk-offcanvas-close @click="show = false"></vk-offcanvas-close>

            <div
                    v-html="drawer.content"
                    v-bind:class="[ drawer.classes ]">
            </div>

        </vk-offcanvas>
    </vk-offcanvas-content>
</template>

<script>
    export default {
        name: "drawer",
        data: function () {
            return {
                debug: false,
                show: false,
                drawer: {}
            }
        },
        methods: {
            openDrawer: function ( id ) {
                if(this.debug) console.log('Open Drawer');

                this.show = true;

                this.drawer.title    = this.config[ this.currentSlide ].elements[id].display_name;
                this.drawer.content  = this.config[ this.currentSlide ].elements[id].content;
                this.drawer.classes  = this.config[ this.currentSlide ].elements[id].classes;
            }
        }
    }
</script>

<style scoped>

</style>