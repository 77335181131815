<template>
    <!-- Image Slide -->
    <div class="uk-width-1-1 uk-cover-container" uk-height-viewport v-show="this.$parent.content_type === 'image'">
        <img id="image" v-bind:class="isImage" uk-cover>
    </div>

</template>

<script>
    if(typeof nw === "undefined")
    {
        let nw = window.nw;
    }
    export default {
        name: "image-slide",
        data: function () {
            return {
                debug   : false,
                image   : null
            }
        },
        computed:  {
            isImage: function () {
                return {
                    'element-transition': this.$parent.content_type !== 'image'
                };
            }
        },
        mounted() {
            if(this.debug) console.log('Mount Image Slide');

            this.image = document.getElementById('image');

            // Broadcast that our component is ready to be used
            this.$emit('mountedSlide', 'image-slide');
        },
        methods: {
            //
            // Standard Component Methods

            // Inits our Component
            load: function(id) {
                const self = this;

                if(this.debug) console.log('Init Image Slide', self.$parent.config[ id ].name, this);

                if( self.$parent.config[ id ].content.source ) {
                    if(self.debug) console.log('Setup Image');

                    if(!self.image) {
                        self.image = document.getElementById('image');
                    }

                    const imageResetHelper = function () {
                        if(self.debug) console.log('Image Slide Loaded');
                        if( self.$parent.config[ id ].content.source ) {
                            self.$parent.content_type = self.$parent.config[id].content.content_type;
                            self.image.removeEventListener('load', imageResetHelper);
                        }
                    };

                    if(self.debug) console.log('Source', self.$parent.config[id].content.source );
                    self.image.addEventListener('load', imageResetHelper);
                    self.image.alt = self.$parent.config[id].content.alt;
                    self.image.src = nw.App.startPath + nw.global.workingDir + self.$parent.config[id].content.source;
                    self.$parent.setupScene( id );
                }
                else {
                    if(self.debug) console.warn('Found no source for the Image!');
                }
            },
            setup: function(id) {
                if (this.debug) console.log('Setup Image Slide');

            },
            reset: function(id) {
                if(this.debug) console.log('Reset Image Slide');

            }
        }
    }
</script>

<style scoped>

</style>
