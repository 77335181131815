<template>
  <div>
    <!-- START Main Frame -->
    <div class="uk-cover-container" uk-height-viewport>

      <!-- Overlay Template -->
      <div id="overlays" class="uk-cover-container uk-width-1-1" uk-height-viewport>
        <div v-for="overlay in config[currentSlide].overlays"
             ref="{overlay.name}"
             v-bind:id="[overlay.name]"
             v-bind:class="[ overlay.classes ]"
             v-bind:style="{ left: overlay.position.x + '%', top: overlay.position.y + '%' }"
             data-track-content=""
             data-content-name="Overlay"
             v-bind:data-content-piece="[overlay.display_name]">
        </div>
      </div>

      <!-- POIs -->
      <div id="points_of_interest" class="uk-width-1-1 uk-height-1-1">
        <a v-for="poi in currentPOIs"
           class="uk-position-absolute uk-transform-center poi"
           @click="poiToggle($event); executeFunctions( poi, poi.events );"
           ref="{poi.name}"
           v-bind:id="[poi.name]"
           v-bind:class="[poi.icon, poi.classes]"
           v-bind:style="{ left: poi.position.x + '%', top: poi.position.y + '%' }"
           data-track-content=""
           data-content-name="PointOfInterest"
           data-content-ignoreinteraction
           v-bind:data-content-piece="[poi.display_name]">
          <span v-html="poi.display_name"></span>
          <img v-if="poi.file" v-bind:id="poi.name" v-bind:alt="poi.display_name" v-bind:src="[assetURL + poi.file]">
        </a>
      </div>

      <!-- START Slide Types -->

      <!-- Bumper Content -->
      <video id="videoPlayerBumper" class="uk-width-1-1 uk-height-1-1" preload="auto" autoplay
             v-show="content_type === 'bumper'"></video>

      <!-- Dynamic Slide Content -->
      <component
          v-for="slide in usedSlideTypes"
          v-bind:key="slide.id"
          v-bind:is="slide + '-slide'"
          :ref="genRefName( slide + '-slide' )"
          @mountedSlide="init"
          data-track-content=""
          data-content-ignoreinteraction
          v-bind:data-content-name="[camelCaseToWords(slide)]"
          v-bind:data-content-piece="[slide.display_name]">
      </component>

      <!-- END Slide Types -->

    </div>
    <!-- END Main Frame -->

    <!-- START Content Elements -->

    <!-- Dynamic Elements -->
    <component
        v-for="(element, index) in usedElementTypes"
        v-bind:index="index"
        v-bind:key="element.id"
        v-bind:is="element + '-element'"
        :ref="genRefName( element + 'Element' )"
        data-track-content=""
        data-content-ignoreinteraction
        v-bind:data-content-name="[camelCaseToWords(element)]"
        v-bind:data-content-piece="[element.display_name]"
    >
    </component>

    <!-- END Content Elements -->
  </div>
</template>

<script>
if (typeof nw === "undefined") {
  let nw = window.nw;
}
export default {
  name: "media-explorer",
  props: ['config'],
  data: function () {
    return {
      debug: false,
      url: null,
      assetURL: null,
      browserWindow: null,    // Browser Window to show external content
      initialized: false,
      usedSlideTypes: [],
      usedElementTypes: [],
      content_type: 'video',
      currentPOIs: [],
      previousSlide: [],
      initialSlide: 0,
      currentSlide: 0,
      nextSlide: 1,
      resetTimer: 0
    }
  },
  mounted: function () {
    this.debug = this.$parent.debug;

    if (this.debug) console.log('Mounted Media Explorer Instance');

    // Than gather a list of the used components within our app
    this.usedSlideTypes = [...new Set(this.config.map(x => x.content.content_type))];
    this.usedSlideTypes = this.usedSlideTypes.filter(item => item !== 'bumper');
    if (this.debug) console.log('Used Slide Types:', this.usedSlideTypes);

    let elements = [...new Set(this.config.map(x => x.elements))];
    elements = [].concat.apply([], elements);   // Flatten our array
    this.usedElementTypes = [...new Set(elements.map(x => x.content.content_type))];
    if (this.debug) console.log('Used Element Types:', this.usedElementTypes);

    // Get refs to our default display elements
    this.videoPlayerBumper = document.getElementById('videoPlayerBumper');

    nw.global.reloadAppData = this.reloadData;

    // and set our initial slide
    this.initialSlide = this.config.findIndex(function (item, i) {
      return item.name === nw.global.manifest.initial_slide_name;
    });

    // Setup our Reset Timer Logic
    if (nw.global.manifest.config.autoReset) {
      if (this.debug) console.log('Apply Auto Reset');
      setInterval(() => {
        this.resetTimer++;
      }, 1000);
      document.body.addEventListener('click', this.userInteraction, true);
    }

    // and get our application url
    this.url = new URL(window.location.href);
    this.assetURL = nw.App.startPath + nw.global.workingDir;
  },
  computed: {
    slideType: function () {
      return this.config[this.nextSlide].content.content_type + '-slide';
    },
    slideRef: function () {
      return _.camelCase(this.slideType);
    }
  },
  watch: {
    config: function (val) {
      if (this.debug) console.log('Application config has changed!', val);

      UIkit.notification('Applied new config', {pos: 'top-right', status: 'success'});

      this.reload();
    },
    show: function (val) {
      if (this.debug) console.log('Updated Panel:', val);

      if (!val)
        this.resetPOIs();
    },
    resetTimer: function (val) {
      if (this.debug) console.log('Updated Reset Timer:', val);

      if (val >= nw.global.manifest.config.resetTime) {
        this.resetTimer = 0;

        const bumperId = this.config.findIndex(function (item, i) {
          return item.name === 'bumper'
        });

        if (bumperId !== -1)
          this.loadSlideWithBumper(this.config[this.initialSlide].name);
        else {
          this.loadSlide(this.config[this.initialSlide].name);
        }
      }
    }
  },
  methods: {
    //
    // Gets called by our dynamic slide component include, thus called for each component
    init: function (name) {
      this.resetTimer = 0;

      // Check if our url contains any parameter
      let search_params = this.url.searchParams;
      let slideParameter = search_params.get('slide');
      let poiParameter = search_params.get('poi');

      // Fallback to our initial slide, if we reached the end of our config
      // (e.g. on single page applications)
      if (!this.config[this.nextSlide])
        this.nextSlide = this.initialSlide;

      if (name === this.config[this.nextSlide].content.content_type + '-slide') {
        if (this.debug) console.log('Init Media Explorer');

        if (this.debug) {
          if (slideParameter)
            console.log("Init Slide: " + slideParameter);
          if (poiParameter)
            console.log("Trigger POI: " + poiParameter);
        }

        if (slideParameter) {
          if (poiParameter) {
            this.$on('onSceneSetup', function (id) {
              setTimeout(function () {
                if (this.debug) console.log('Trigger Loaded POI: ' + poiParameter);
                const target = document.getElementById(poiParameter);
                if (target) target.click();
              }.bind(this), 200);
            })
          }
          this.nextSlide = this.setNextSlide(slideParameter);
          this.swapContent(slideParameter);
        } else {
          this.nextSlide = this.setNextSlide(this.initialSlide);
          this.swapContent(this.initialSlide);
        }
      } else {
        if (this.debug) console.log('Component was mounted', name);

        if (this.usedSlideTypes[this.usedSlideTypes.length - 1] + '-slide' == name) {
          if (this.debug) console.log("All App Components Loaded!");

          this.trackEvent('Status', 'App.Loaded', this.config[this.currentSlide].display_name);
        }
      }

    },
    // Generic Helpers
    executeFunctions: function (poi, events) {
      if (this.debug) console.log('Execute Functions:', poi, events);
      for (let i = 0; i < events.length; i += 1) {
        this.executeFunctionByName(poi.events[i].function, poi.events[i].parameter);
      }
    },
    executeFunctionByName: function (functionName, parameter) {
      if (this.debug) console.log('Execute Function:', functionName, parameter);

      // Separate our function call for elements, as we need to call them on our components
      if (functionName.includes('Element')) {
        const commands = functionName.match(/[A-Z]?[a-z]+/g);
        let componentName = functionName.replace(commands[0], '');
        componentName = componentName.charAt(0).toLowerCase() + componentName.slice(1);
        if (Array.isArray(parameter))
          this.$refs[componentName][0][commands[0]](...parameter);
        else
          this.$refs[componentName][0][commands[0]](parameter);

      } else {
        if (typeof this[functionName] !== "undefined") {
          if (Array.isArray(parameter))
            this[functionName](...parameter);
          else
            this[functionName](parameter);
        } else {
          if (Array.isArray(parameter))
            this.$refs[this.slideRef][0][functionName](...parameter);
          else
            this.$refs[this.slideRef][0][functionName](parameter);
        }
      }
    },
    genRefName: function (id) {
      return _.camelCase(id);
    },
    camelCaseToWords: function (str) {
      return str.match(/^[a-z]+|[A-Z][a-z]*/g).map(function (x) {
        return x[0].toUpperCase() + x.substr(1).toLowerCase();
      }).join(' ');
    },
    trackEvent(category, action, name, value = null) {
      if (typeof _paq !== 'undefined') {
        _paq.push(['trackEvent', category, action, name, value]);
      }
    },
    trackContentInteraction(name, content_piece, target = null, interaction = null) {
      if (typeof _paq !== 'undefined') {
        _paq.push(['trackContentInteraction', name, content_piece, target, interaction]);
      }
    },
    // General Logic
    userInteraction: function () {
      if (this.debug) console.log('Registered a User Interaction', this.resetTimer);
      this.resetTimer = 0;
    },
    // Scene functionality
    reset: function () {
      if (this.debug) console.log('Reset App from slide:', this.currentSlide);
      this.trackEvent('Status', 'App.Reset', this.config[this.currentSlide].display_name);

      this.resetTimer = 0;
      this.previousSlide = [];

      const bumperId = this.config.findIndex(function (item, i) {
        return item.name === 'bumper'
      });

      if (bumperId !== -1)
        this.loadSlideWithBumper(this.config[this.initialSlide].name);
      else {
        if (this.currentSlide === this.initialSlide)
          this.resetScene(this.currentSlide);

        this.loadSlide(this.config[this.initialSlide].name);
      }

      this.executeFunctionByName('closeDrawerElement', ' ');
    },
    reload: function () {
      if (this.debug) console.log('Reload application');
      this.trackEvent('Status', 'App.Reload', this.config[this.currentSlide].display_name);

      this.resetScene();
      this.swapContent();
    },
    setupScene: function (id) {
      if (this.debug) console.log('Setup Scene:', id);

      if (id === undefined) {
        id = this.currentSlide;
        if (this.debug) console.log('For id:', id);
      } else {
        this.currentSlide = parseInt(id);
        // update our url object
        this.updateQueryParameter(id);
        if (this.debug) console.log('Setup Slide id:', this.currentSlide);
      }

      // TODO Hardcoded?!
      // Setup existing overlays
      const overlays = this.config[id].overlays;
      if (overlays !== undefined && overlays.length > 0) {
        for (let i = 0; i < overlays.length; i += 1) {
          if (this.debug) console.log('Add Overlay For: ', overlays[i].name);

          // Skip empty/broken configs
          if (typeof overlays[i].content == 'undefined')
            continue;

          // TODO Load async to avoid hick-ups during load
          if (overlays[i].content.content_type === 'particlesJS') {
            particlesJS.load(overlays[i].name, overlays[i].content.config, function () {
              console.log('callback - particles.js config loaded');
            });
          }
        }
      }

      this.$refs[this.slideRef][0].setup(id);

      // Setup our POIs
      this.currentPOIs = {};
      if (this.config[id].content.content_type !== 'superzoom') {
        setTimeout(function () {
          this.currentPOIs = Object.assign({}, this.config[id].point_of_interests);
          for (var i = 0; i < this.config[id].point_of_interests.length; i++)
            this.currentPOIs[i].id = id + "_" + this.currentPOIs[i].name;
        }.bind(this), 100);
      } else this.currentPOIs = Object.assign({}, this.config[id].point_of_interests);

      // Setup existing elements
      const elements = this.config[id].elements;
      if (elements !== undefined && elements.length > 0) {
        for (let i = 0; i < elements.length; i += 1) {
          if (this.debug) console.log('Setup Element For: ', elements[i].name);
          this.$refs[_.camelCase(elements[i].content.content_type + 'Element')][0].setup(i);
        }
      }

      this.$emit('onSceneSetup', id);
    },
    resetScene: function (id) {
      if (this.debug) console.log('Reset Scene', this.currentSlide);

      // Reset our POIs
      this.resetPOIs();

      if (id === undefined) {
        id = this.currentSlide;
        if (this.debug) console.log('Reset Scene for Slide id:', id);
      } else {
        this.currentSlide = parseInt(id);
        if (this.debug) console.log('Reset Scene for Slide id:', this.currentSlide);
        // update our url object
        this.updateQueryParameter(id);
      }

      this.$refs[this.slideRef][0].reset(id);

      // Setup our POIs
      this.currentPOIs = {};

      // Setup existing elements
      this.resetElements(id);
    },
    // Slide loading functionality
    loadNextSlide: function () {
      this.previousSlide.push(this.currentSlide);
      this.nextSlide = this.setNextSlide(parseInt(this.currentSlide) + 1);

      if (this.debug) console.log('Next Slide:', this.nextSlide);

      this.resetScene(this.currentSlide);
      this.swapContent(this.nextSlide);
    },
    loadSlide: function (slideName) {
      const id = !isNaN(slideName) ? slideName : this.config.findIndex(function (item, i) {
        return item.name === slideName
      });
      if (this.debug) console.log('Load Slide:', id);

      this.previousSlide.push(this.currentSlide);
      this.nextSlide = this.setNextSlide(id);

      this.resetScene();
      this.swapContent();
    },
    loadSlideWithBumper: function (slideName) {
      if (this.debug) console.log('Load Slide with Bumper:', slideName);

      this.previousSlide.push(this.currentSlide);

      this.resetElements(this.currentSlide);

      // Check if our slideName is a string or and ID for a direct call (reset eg.)
      const id = !isNaN(slideName) ? slideName : this.config.findIndex(function (item, i) {
        return item.name === slideName
      });
      const bumperId = this.config.findIndex(function (item, i) {
        return item.name === 'bumper'
      });

      this.swapContent(bumperId);

      // Overwrite our next slide, as our generic bumper slide cannot define its next slide!
      this.nextSlide = this.setNextSlide(id);
    },
    loadNextSlideWithBumper: function () {
      if (this.debug) console.log('Next Slide from Bumper:', this.currentSlide + 1);

      this.previousSlide.push(this.currentSlide);
      this.nextSlide = this.setNextSlide(this.currentSlide + 1);

      this.resetElements(this.currentSlide);

      const bumperId = this.config.findIndex(function (item, i) {
        return item.name === 'bumper'
      });
      this.currentSlide = parseInt(bumperId);

      this.swapContent(bumperId);
    },
    loadPreviousSlide: function () {
      this.nextSlide = this.setNextSlide(this.previousSlide.pop());

      if (this.debug) console.log('Previous Slide:', this.nextSlide);

      this.resetScene();
      this.swapContent();
    },
    loadPreviousSlideWithBumper: function () {
      if (this.debug) console.log('Previous Slide from Bumper:', this.previousSlide);

      this.nextSlide = this.setNextSlide(this.previousSlide.pop());

      this.resetElements(this.currentSlide);

      const bumperId = this.config.findIndex(function (item, i) {
        return item.name === 'bumper'
      });
      this.currentSlide = parseInt(bumperId);

      this.swapContent(bumperId);
    },
    setNextSlide: function (id) {
      return id < this.config.length ? id : this.initialSlide;
    },
    // POI Logic
    poiToggle: function (event) {
      if (this.debug) console.log('POI Toggle:', event.target);

      try {
        // Get correct target, e.g. our main poi element
        let target = event.target.classList.contains('poi') ? event.target : event.target.parentElement;

        // Remove our Query Parameter only, if we actively defined our POI as a toggle
        if (target.classList.contains('toggle')) {
          if (target.classList.contains('active')) {
            if (this.debug) console.log('Disable POI:', target);
            target.classList.remove('active');

            this.trackContentInteraction('Disabled', 'POI', target.getAttribute('data-content-piece'));
            this.trackEvent('POI', 'Disable', target.getAttribute('data-content-piece'));
          } else {
            if (this.debug) console.log('Enable POI:', target, target.id);
            target.classList.add('active');
            this.updateQueryParameter(this.currentSlide, target.id);

            this.trackContentInteraction('Activated', 'POI', target.getAttribute('data-content-piece'));
            this.trackEvent('POI', 'Activate', target.getAttribute('data-content-piece'));
          }
        } else {
          this.updateQueryParameter(this.currentSlide, target.id);

          this.trackContentInteraction('Triggered', 'POI', target.getAttribute('data-content-piece'));
          this.trackEvent('POI', 'Triggered', target.getAttribute('data-content-piece'));
        }
      } catch (error) {
        if (this.debug)
          console.error('Error in POI Toggle:', error)
      }

    },
    resetPOIs: function () {
      if (this.debug) console.log('Reset POIs');
      const pOIs = document.getElementById('points_of_interest').getElementsByClassName('poi');
      if (pOIs.length > 0) {
        for (let i = 0; i < pOIs.length; i++) {
          pOIs[i].classList.remove('uk-hidden');
          pOIs[i].classList.remove('active');
        }
      }
    },
    resetElements: function (id) {
      if (this.debug) console.log('Reset Elements');
      const elements = this.config[id].elements;
      if (elements !== undefined && elements.length > 0) {
        for (let i = 0; i < elements.length; i += 1) {
          if (this.debug) console.log('Reset Element For: ', elements[i].name);
          this.$refs[_.camelCase(elements[i].content.content_type + 'Element')][0].reset(i);
        }
      }
    },
    // Slide Type Logic
    swapContent: function (id) {
      if (this.debug) console.log('Swap Content', id, this.slideRef);

      if (id === undefined) {
        id = this.nextSlide;
        if (this.debug) console.log('For id:', id);
      }

      this.currentSlide = parseInt(id);
      if (this.debug) console.log('Swap Content for Slide id:', this.currentSlide);
      // update our url object
      this.updateQueryParameter(id);

      // and log our loaded page with our client friendly name
      if (typeof _paq !== 'undefined') {
        _paq.push(['setCustomUrl', this.url.href]);
        _paq.push(['setDocumentTitle', this.config[id].display_name]);
        _paq.push(['trackPageView']);
      }
      this.trackEvent('Status', 'Scene.Load', this.config[this.currentSlide].display_name);

      if (this.config[id].content.content_type === 'bumper')
        this.swapVideoBumper(id);
      else this.$refs[this.slideRef][0].load(id);

      // Setup our POIs
      this.currentPOIs = {};
      if (this.config[id].content.content_type === 'modelviewer') {
        setTimeout(function () {
          this.currentPOIs = Object.assign({}, this.config[id].point_of_interests);
          document.getElementById("app").dispatchEvent(new Event('syncPOIsToPlaycanvas'));
        }.bind(this), 100);
      } else if (this.config[id].content.content_type !== 'superzoom') {
        setTimeout(function () {
          this.currentPOIs = Object.assign({}, this.config[id].point_of_interests);
          for (var i = 0; i < this.config[id].point_of_interests.length; i++)
            this.currentPOIs[i].id = id + "_" + this.currentPOIs[i].name;
        }.bind(this), 100);
      } else this.currentPOIs = Object.assign({}, this.config[id].point_of_interests);

      // Setup existing elements
      const elements = this.config[id].elements;
      if (elements !== undefined && elements.length > 0) {
        for (let i = 0; i < elements.length; i += 1) {
          if (this.debug) console.log('Load Element For: ', elements[i].name);
          this.$refs[_.camelCase(elements[i].content.content_type + 'Element')][0].load(i);
        }
      }
    },
    // Bumper Logic, special slide type
    swapVideoBumper: function (id) {
      if (this.debug) console.log('Swap Video Bumper:', id);
      const self = this;

      if (self.config[id].content.source) {
        if (self.debug) console.log('Hide Video');

        self.videoPlayerBumper.style.display = 'none';

        const videoResetHelper = function () {
          if (self.debug) console.log('Video Reset');
          self.videoPlayerBumper.removeEventListener('canplaythrough', videoResetHelper);
          self.videoPlayerBumper.style.display = 'block';

          setTimeout(function () {
            if (self.debug) console.log('Bumper Delay:' + self.config[id].content.delay);
            self.swapContent(self.nextSlide);
          }, self.config[id].content.delay);
        };

        self.videoPlayerBumper.addEventListener('canplaythrough', videoResetHelper);

        self.videoPlayerBumper.src = nw.App.startPath + nw.global.workingDir + self.config[id].content.source;
      } else {
        if (this.debug) console.warn('Found no Source for the Video Bumper!');
      }
    },
    // Component Type Specific functionality, TODO should be separated
    openWebsite: function (url) {
      if (this.debug) console.log('Open Website: ' + url);
      this.trackEvent('Content', 'Website.Open', url);

      if (nw.global.webBased)
        window.open(url);
      else
        nw.Shell.openExternal(url);
    },
    openFile: function (path) {
      if (this.debug) console.log('Open File: ' + path);
      this.trackEvent('Content', 'File.Open', path);

      if (nw.global.webBased) {
        if (path.startsWith("./"))   // Check if we try to open a local file
          path = window.applicationPath + path.replace('./', '');   // if so append our app working dir to its path

        window.open(path);
      } else {
        if (path.startsWith("./"))   // Check if we try to open a local file
          path = nw.App.startPath + nw.global.workingDir + path.replace('./', '');   // if so append our app working dir to its path

        if (nw.global.helper.fileExists(path))
          nw.Shell.openExternal(path);
        else {
          if (this.debug) console.log('File not found: ' + path);

          if (nw.global.developmentMode) {
            UIkit.notification({
              message: '<span class="uk-width-auto">Couldnt open file: ' + path + '!</span>',
              status: 'warning',
              pos: 'top-center',
              timeout: 5000
            });
          }
        }
      }
    },
    // Editor API
    reloadData: function () {
      if (this.debug) console.log('Reload Data');

      this.config = nw.global.config;
    },
    updateQueryParameter: function (slideID = null, poiName = null) {
      if (typeof this.config[slideID] == 'undefined')
        return;

      if (this.debug)
        console.log('Update Query Parameter. Slide & POI: ', slideID, poiName);

      let search_params = this.url.searchParams;
      search_params.set('slide', this.currentSlide);
      if (poiName != null)
        search_params.set('poi', poiName);
      else
        search_params.delete('poi')

      // and push it to our browser history, to update our document url
      let config = JSON.stringify(this.config[slideID]);
      window.history.pushState(config, this.config[slideID].display_name, this.url.href);
    }
  }
}
</script>

<style scoped>

</style>
