<template>
  <!-- SuperZoom Slide -->
  <div class="uk-width-1-1 uk-cover-container" uk-height-viewport v-show="this.$parent.content_type === 'superzoom'">
    <div id="super_zoom_viewer" uk-cover>

    </div>
    <a
        class="uk-position-absolute"
        href="#" uk-marker
        v-on:click="getCoords()"
        v-bind:class="[ 'uk-text-uppercase', 'uk-text-bold', 'uk-transform-left' ]"
        v-bind:style="{ left: 10 + '%', top: 25 + '%' }"
        v-show="development">
      GetCoords
    </a>
  </div>
</template>

<script>
if (typeof nw === "undefined") {
  let nw = window.nw;
}
import OpenSeadragon from 'openseadragon';

export default {
  name: "superzoom-slide",
  data: function () {
    return {
      debug: false,
      development: false,    // Enables a dev icon to quickly copy the current camera position to your clipboard
      home: true,
      navigator: false,    // Adds a mini map to the gfxViewer (detail viewer), requires you to enable the navigator boolean on the gfxViewer Config
      viewer: null,
      position: null,
      zoom: null,
      config: [],       // Local config copy for easier path adjustments
    }
  },
  mounted() {
    if (this.debug) console.log('Mount SuperZoom Slide');

    this.development = nw.global.developmentMode;

    // Apply custom styling to our minimap
    if (this.navigator) {
      // Overwrite the style of the minibar
      var navigator = document.getElementsByClassName('navigator');
      navigator[0].style.background = "#ebe6d3";
      navigator[0].style.border = "2px solid #fff";
      navigator[0].style.borderTop = "none";
      // and its display region
      var displayRegion = document.getElementsByClassName('displayregion');
      displayRegion[0].style.border = "2px solid #d76921";
    }

    // Broadcast that our component is ready to be used
    this.$emit('mountedSlide', 'superzoom-slide');
  },
  methods: {
    //
    // Standard Component Methods
    // Called to enable (activate our module)
    load: function (id) {
      if (this.debug) console.log('Init SuperZoom Slide', this.$parent.config[id].name);

      if (this.viewer) {
        this.config = [];
        this.viewer.destroy();
        this.viewer = null;
      }
      // Make a copy of our parents relevant config
      this.config = JSON.parse(JSON.stringify(this.$parent.config[id].content));
      // and apply it to our vue instance
      this.home = this.config.home;
      this.navigator = this.config.navigator;

      // Create a new Viewer instance with the settings of the current slide defined on our parent element
      this.viewer = OpenSeadragon(this.config.settings);
      this.viewer.viewport.panTo(new OpenSeadragon.Point(Number(this.config.position.x), Number(this.config.position.y)), true);
      this.viewer.viewport.zoomTo(Number(this.config.settings.defaultZoomLevel), true);

      // Register custom event for camera transitions
      this.viewer.addHandler('animation-finish', this.reachedTarget);

      this.$parent.content_type = this.$parent.config[id].content.content_type;

      this.$parent.setupScene(id);
    },
    setup: function (id) {
      if (this.debug) console.log('Setup SuperZoom Slide');

      setTimeout(() => {
        if (this.home) {
          this.goHome();
        } else {
          this.viewer.viewport.panTo(new OpenSeadragon.Point(Number(this.config.position.x), Number(this.config.position.y)), true);
          this.viewer.viewport.zoomTo(Number(this.config.settings.defaultZoomLevel), true);
        }
      }, 100);
    },
    reset: function (id) {
      if (this.debug) console.log('Reset SuperZoom Slide');

      if (this.$parent.config[id].content.content_type === 'superzoom') {
        this.viewer.viewport.panTo(new OpenSeadragon.Point(Number(this.config.position.x), Number(this.config.position.y)), true);
        this.viewer.viewport.zoomTo(Number(this.config.settings.defaultZoomLevel), true);

        this.showOverlays();
      }
    },

    //
    // Custom Component Methods

    // Reset the camera position
    goHome: function () {
      if (this.debug) console.log('Go Home:', this.viewer);
      this.home = true;
      this.viewer.viewport.goHome();
      this.viewer.setMouseNavEnabled(true);

      if (this.navigator)
        document.getElementsByClassName('navigator')[0].style.display = 'none';

      this.$parent.trackEvent('SuperZoom', 'GoHome', this.$parent.config[this.$parent.currentSlide].display_name);
    },
    // Transition the camera to the given coordinates
    goTo: function (coordinates) {
      if (this.debug) console.log('GoTo:', coordinates);

      const position = coordinates.split(",");
      this.home = false;

      this.viewer.viewport.panTo(new OpenSeadragon.Point(Number(position[1]), Number(position[2])));
      this.viewer.viewport.zoomTo(Number(position[0]));
      this.viewer.setMouseNavEnabled(false);

      if (this.navigator)
        document.getElementsByClassName('navigator')[0].style.display = 'block';

      this.$parent.trackEvent('SuperZoom', 'GoTo', this.$parent.config[this.$parent.currentSlide].display_name, coordinates);
    },
    // Fired, when the camera reached a new position
    reachedTarget: function (event) {
      if (this.development || this.debug) console.log('Reached Target');

    },
    //
    showOverlays: function () {
      if (this.debug) console.log('Show all Overlays');

      const points = document.getElementsByClassName('superzoom-poi');

      for (let i = 0; i < points.length; i++)
        points[i].style.display = 'flex !important';
    },
    showOverlay: function (id) {
      if (this.debug) console.log('Show Overlay:', id);
      document.getElementById(id).classList.remove('uk-hidden');
    },
    hideOverlay: function () {
      if (this.debug) console.log('Hide Overlay');
      const points = document.getElementsByClassName('superzoom-poi');

      for (let i = 0; i < points.length; i++)
        points[i].classList.add('uk-hidden');
    },
    // Dev Commands for a smoother app setup
    getCoords: function () {
      const coordinates = this.viewer.viewport.getCenter();
      const zoomLevel = this.viewer.viewport.getZoom();

      this.copyToClipboard(zoomLevel.toFixed(3) + ',' + coordinates.x.toFixed(3) + ',' + coordinates.y.toFixed(3));

      if (this.debug) {
        console.log('Get Viewport Coordinates: ', coordinates);
        console.log('Get Zoom: ', zoomLevel);
        console.log('Conversion: ', this.viewer.viewport.viewportToImageCoordinates(coordinates));
      }
    },
    // Helper Function to Copy the current Camera position to the Clipboard of the ,hopefully, developer)
    copyToClipboard(text) {
      const dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.setAttribute('value', text);
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    }
  }
}
</script>

<style scoped>

</style>
