<template>

    <div class="uk-width-1-1 uk-height-1-1 uk-container-large uk-padding" uk-height-viewport v-show="this.$parent.content_type === 'pagebuilder'">
        <div v-bind:class="[ classes ]" v-html="layout" >

        </div>
    </div>

</template>

<script>
    var dot = require('dot-object');

    if(typeof nw === "undefined")
    {
        let nw = window.nw;
    }
    export default {
        name: "pagebuilder-slide",
        data: function () {
            return {
                debug   : false,
                classes : [ 'uk-height-max-large', 'uk-card', 'uk-card-secondary', 'uk-card-body', 'uk-margin', 'uk-width-2-3', 'uk-align-center', 'uk-overflow-auto' ],
                layout  : null
            }
        },
        computed:  {

        },
        mounted() {
            if(this.debug) console.log('Mount Page Builder Slide');

            // Broadcast that our component is ready to be used
            this.$emit('mountedSlide', 'pagebuilder-slide');
        },
        methods: {
            // Helper to replace vue variables inside a string
            replaceManifestVariables: function (str) {
                // First Replace any specific cases
                str = str.replace('{{ licenses.list }}', nw.global.licenses);

                // Then lookup all our variables inside the string
                let reg = /\{\{(.*?)\}\}/g;

                // and replace them
                let found;
                while (found = reg.exec(str)) {
                    found[1] = found[1].replace("manifest.", "");
                    found[1] = found[1].trim();
                    if ( dot.pick( found[1], nw.global.manifest) ) {
                        // with their manifest values
                        str = str.replace(found[0], dot.pick( found[1], nw.global.manifest) )
                    } else {
                        // or an placeholder value
                        str = str.replace(found[0], '--')
                    }
                }

                return str
            },
            //
            // Standard Component Methods

            // Inits our Component
            load: function(id) {
                if(this.debug) console.log('Init Page Builder Slide', this.$parent.config[ id ].name, this);

                // get our starting slide parameters
                let layout = this.$parent.config[ id ].content.html_layout;
                // Replace our Vue Variables inside our content.
                this.layout = this.replaceManifestVariables(layout);

                if(this.$parent.config[ id ].classes.length > 0) {
                    this.classes = this.$parent.config[id].classes;
                }

                this.$parent.content_type = this.$parent.config[ id ].content.content_type;
                this.$parent.setupScene( id );
            },
            setup: function(id) {
                if (this.debug) console.log('Setup Page Builder Slide');

            },
            reset: function(id) {
                if(this.debug) console.log('Reset Page Builder Slide');

            }
        }
    }
</script>

<style scoped>

</style>
