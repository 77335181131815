/*
 **************************************************
 *  Import all required Dependencies for our App  *
 **************************************************
 */
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import _ from 'lodash';
import Vue from 'vue';
import Vuikit from 'vuikit';
import VuikitIcons from '@vuikit/icons';
import UIkit from 'uikit'; //jshint ignore:line
import PrettyCheckbox from 'pretty-checkbox-vue';

// Media Explorer Parts
import './libs/pathseg';    // Polyfill for our particle masking
import './libs/particles';  // Non-npm fork with svg maskiing option

// Component Dependencies
import Splide from '@splidejs/splide';
import Video from '@splidejs/splide-extension-video';

window.Splide = Splide;
window.SplideVideo = Video;

import Glightbox from "glightbox/src/js/glightbox";

window.Glightbox = Glightbox;

import '@editorjs/personality';

const debug = false;
//
// and load our Vue components
Vue.use(Vuikit);
Vue.use(VuikitIcons);
Vue.use(PrettyCheckbox);
Vue.component('options-menu', require('./options-menu.vue').default);
Vue.component('media-explorer', require('./media-explorer.vue').default);

// Auto Mount all existing media explorer components
const req = require.context('./components/', true, /\.(js|vue)$/i);
req.keys().map(key => {
    const name = key.split("/").slice(-1).join().split(".").shift();
    if (debug) console.log('Mount Component:', name, req(key));
    return Vue.component(name, req(key).default);
});


// Finally initialize our vue instance
window.addEventListener("load", function (event) {
    // Web Version Capability
    if (typeof nw === "undefined") {
        let nw = {
            global: {
                webBased: true,
                licenses: '',
                manifest: {
                    config: {},
                    languages: {}
                },
                config: [],
                workingDir: '/',
                developmentMode: false
            },
            App: {
                startPath: './../../',
            }
        };
        window.nw = nw;
    }
    window.UIkit = UIkit;
    window.Vue = Vue;
    window.vue = new Vue({ //jshint ignore:line
        el: '#app',
        data: {
            debug: debug,
            showAbout: false,
            fullScreen: false,
            language: null,
            packageDataLoaded: false,
            configDataLoaded: false,
            pages: nw.global.pages,
            config: nw.global.config,
            manifest: nw.global.manifest,
            licenses: nw.global.licenses
        },
        created: function () {
            this.getConfig();
        },
        mounted: function () {

        },
        computed: {
            versionString: function () {
                // `this` points to the vm instance
                return 'About - ' + this.manifest.version;
            }
        },
        watch: {},
        methods: {
            mount: function () {
                if (this.debug) console.log('Mounted Vue App Instance', Vue.options.components);

                // Log any issues with sentry
                if (this.manifest.config.sentryID && !nw.global.developmentMode) {
                    if (this.debug) console.log('Enable Sentry Front-End Logging');
                    Sentry.init({
                        dsn: this.manifest.config.sentryID,
                        integrations: [new Integrations.Vue({Vue, attachProps: true})],
                        release: this.manifest.name + '@' + this.manifest.version,
                    });
                }

                // Setup our Index Document
                if (nw.global.manifest.window.title) document.title = nw.global.manifest.window.title;

                //
                // Set-up our PWA Workers
                // We append the link to our applications config, so our service worker is able to fetch the list of required assets from it
                // But only, if a Config file is supplied
                if (window.pwaConfigPath) {
                    if ('serviceWorker' in navigator) {
                        navigator.serviceWorker.register(window.buildPath + '/resources/js/webkit/sw.js?package=' + window.packagePath + '&pwaConfig=' + window.pwaConfigPath);
                    }
                }
            },
            getConfig: function (locale = null) {

                // Get our relevant url parameters
                // and get our application url
                this.url = new URL(window.location.href);
                let searchParams = this.url.searchParams;
                let languageParameter = searchParams.get('lang');
                let debugParameter = searchParams.get('debug');
                let devParameter = searchParams.get('dev');
                let remoteConfig = searchParams.get('remote');

                if (debugParameter) {
                    console.log("Debug Mode Enabled");
                    this.debug = true;
                }
                if (devParameter) {
                    console.log("Developer Mode Enabled");
                    nw.global.developmentMode = true;
                }
                if (languageParameter || locale) {
                    if (!locale)
                        locale = languageParameter;
                    console.log("Set language to: " + locale);

                    this.language = locale;
                    if (window.configPath.endsWith('config'))
                        window.configPath += '/' + locale;
                    else
                        window.configPath = window.configPath.replace(/[^/]*$/, locale);
                }

                // Look for a configuration overwrite, within our URL parameters
                // Only, if we are within a local environment
                if (location.hostname === 'localhost' && nw.global.developmentMode) {
                    if (remoteConfig !== null) {
                        console.log('Load Custom Config from: ' + remoteConfig);
                        nw.global.webBased = true;
                        window.applicationPath = remoteConfig;
                        window.packagePath = remoteConfig + '/package.json';
                        window.configPath = remoteConfig + '/config.json';
                        window.licensesPath = remoteConfig + '/licenses.json';
                    }
                }

                // Load our application config for web
                if (nw.global.webBased) {
                    if (this.debug) console.log('Load Web Config');

                    const axios = require('axios');

                    axios.all([
                        axios.get(window.packagePath),
                        axios.get(window.configPath),
                        axios.get(window.licensesPath)
                    ])
                        .then(axios.spread((firstResponse, secondResponse, thirdResponse) => {

                            if (this.debug) console.log('Loaded Package', firstResponse.data);
                            nw.global.manifest = firstResponse.data;
                            this.manifest = firstResponse.data;

                            this.language = Object.keys(this.manifest.languages)[0];

                            if (this.debug) console.log('Loaded Config', secondResponse.data);
                            nw.global.config = secondResponse.data.content;
                            this.config = secondResponse.data.content;

                            if (this.debug) console.log('Loaded Licenses', thirdResponse.data);
                            nw.global.licenses = thirdResponse.data.content;
                            this.licenses = thirdResponse.data.content;

                            nw.global.pages = secondResponse.data.pages;
                            this.pages = secondResponse.data.pages;

                            this.configDataLoaded = true;
                            this.mount();
                        }))
                        .catch(error => console.log(error));

                    nw.App.startPath = window.applicationPath;
                } else {
                    if (this.debug) console.log('Load Standalone Config');
                    this.manifest = nw.global.manifest;
                    this.config = nw.global.config.content;
                    this.licenses = nw.global.licenses;
                    this.pages = nw.global.config.pages;

                    this.configDataLoaded = true;
                    this.mount();
                }
            },
            reload: function () {
                if (this.debug) console.log('--- Reload Media Explorer ---');
                this.configDataLoaded = false;
                this.getConfig();
            },
            reset: function () {
                window.vue.$refs.mediaExplorer.reset();
            },
            changeLanguage: function (languageCode) {
                console.log("Change Language to: " + languageCode);
                this.configDataLoaded = false;
                this.getConfig(languageCode);
            },
            toggleFullscreen: function (value = null) {
                let applicationFrame = document.getElementById('app');

                if (value || !this.fullScreen) {
                    if (applicationFrame.requestFullscreen) {
                        applicationFrame.requestFullscreen();
                    } else if (applicationFrame.webkitRequestFullscreen) { /* Safari */
                        applicationFrame.webkitRequestFullscreen();
                    } else if (applicationFrame.msRequestFullscreen) { /* IE11 */
                        applicationFrame.msRequestFullscreen();
                    }
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) { /* Safari */
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) { /* IE11 */
                        document.msExitFullscreen();
                    }
                }

                this.fullScreen = !this.fullScreen;
            },
            openWindow: function (file, options) {
                if (this.debug) console.log('Open Window:', file, options);
                nw.global.openWindow(file, options);
            },
            resizeWindow: function (file, options) {
                if (this.debug) console.log('Resize Window:', file, options);
                nw.global.resizeWindow();
            },
            loadSlide: function (id) {
                window.vue.$refs.mediaExplorer.loadSlide(id);
            },
            executeFunctionByName: function (name, parameter) {
                window.vue.$refs.mediaExplorer.executeFunctionByName(name, parameter);
            }
        }
    });
});

